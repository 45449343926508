
const appConfig = window.AppConfig || {};

let initialized = false;
let configs;

/**
 * Set and valid configs from process.env.
 *
 * @throws {Error} Will throw error if validation fails.
 */
export const initConfigs = () => {
  configs = {
    API_ENDPOINT: appConfig.REACT_APP_API_ENDPOINT || process.env.REACT_APP_API_ENDPOINT || '',
    CHECK_SERVICEABILITY: appConfig.REACT_APP_CHECK_SERVICEABILITY || process.env.REACT_APP_CHECK_SERVICEABILITY || '',
    SHOW_BUNDLE: appConfig.REACT_APP_SHOW_BUNDLE || process.env.REACT_APP_SHOW_BUNDLE || '',
    CHECK_PHONE: appConfig.REACT_APP_CHECK_PHONE || process.env.REACT_APP_CHECK_PHONE || '',
    EXPRESS_VAS: appConfig.REACT_APP_EXPRESS_VAS || process.env.REACT_APP_EXPRESS_VAS || '',
    CHASEPAYMENT_URL_GETKEY: appConfig.REACT_APP_CHASEPAYMENT_URL_GETKEY || process.env.REACT_APP_CHASEPAYMENT_URL_GETKEY || '',
    CHASEPAYMENT_URL_ENCRYPTION: appConfig.REACT_APP_CHASEPAYMENT_URL_ENCRYPTION || process.env.REACT_APP_CHASEPAYMENT_URL_ENCRYPTION || '',
    APP_VERSION: appConfig.REACT_APP_APP_VERSION || process.env.REACT_APP_APP_VERSION || '1.0',
    BUSINESS_PARTNER: appConfig.REACT_APP_BUSINESS_PARTNER || process.env.REACT_APP_BUSINESS_PARTNER || 'elnkwww',
    RESIDENTIAL_PARTNER: appConfig.REACT_APP_RESIDENTIAL_PARTNER || process.env.REACT_APP_RESIDENTIAL_PARTNER || 'elnkafil',
    SMARTY_API_KEY: appConfig.REACT_APP_SMARTY_API_KEY || process.env.REACT_APP_SMARTY_API_KEY || '',
    RESULTS_URL: appConfig.REACT_APP_RESULTS_URL || process.env.REACT_APP_RESULTS_URL || 'http://results.earthlink.com/',
    BASE_URL: appConfig.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || 'http://results.earthlink.com/',
    SHOW_SCHEDULE_APPT: appConfig.REACT_APP_SHOW_SCHEDULE_APPT || process.env.REACT_APP_SHOW_SCHEDULE_APPT || false,
    SHOW_PSWD_CQAS: appConfig.REACT_APP_SHOW_PSWD_CQAS || process.env.REACT_APP_SHOW_PSWD_CQAS || false,
    TCPA_API_BASE_URL: appConfig.REACT_APP_TCPA_API_BASE_URL || process.env.REACT_APP_TCPA_API_BASE_URL || '',
    SHOW_MOBILE_OPTIONS: appConfig.REACT_APP_SHOW_MOBILE_OPTIONS || process.env.REACT_APP_SHOW_MOBILE_OPTIONS || 'false',
    CALL_CENTER_TIME: appConfig.REACT_APP_CALL_CENTER_TIME || process.env.REACT_APP_CALL_CENTER_TIME || false,
    SHOW_CALL_NOW: appConfig.REACT_APP_SHOW_CALL_NOW || process.env.REACT_APP_SHOW_CALL_NOW || false,
    SHOW_TERMS_CONTRACT: appConfig.REACT_APP_SHOW_TERM_CONTRACT || process.env.REACT_APP_SHOW_TERM_CONTRACT || 'false',
    LEADS_SDK_SCRIPT_SRC: appConfig.REACT_APP_LEADS_SDK_SCRIPT_SRC || process.env.REACT_APP_LEADS_SDK_SCRIPT_SRC || '',
    HEADLESS_CHECKOUT_BASE_URL: appConfig.REACT_APP_HEADLESS_CHECKOUT_BASE_URL || process.env.REACT_APP_HEADLESS_CHECKOUT_BASE_URL || 'https://checkout.earthlink.com',
    BUYFLOW_ENABLED: '',
  };

  if (!/^http(s)?:/i.test(configs.API_ENDPOINT)) {
    throw new Error('Expected REACT_APP_API_ENDPOINT to be valid uri');
  }
  if (!/^http(s)?:/i.test(configs.CHECK_SERVICEABILITY)) {
    throw new Error('Expected REACT_APP_CHECK_SERVICEABILITY to be valid uri');
  }
  if (!/^http(s)?:/i.test(configs.CHECK_PHONE)) {
    throw new Error('Expected REACT_APP_CHECK_PHONE to be valid uri');
  }
  if (!/^http(s)?:/i.test(configs.CHASEPAYMENT_URL_GETKEY)) {
    throw new Error('Expected REACT_APP_CHASEPAYMENT_URL_GETKEY to be valid uri');
  }
  if (!/^http(s)?:/i.test(configs.CHASEPAYMENT_URL_ENCRYPTION)) {
    throw new Error('Expected REACT_APP_CHASEPAYMENT_URL_ENCRYPTION to be valid uri');
  }
  if (!configs.SMARTY_API_KEY || configs.SMARTY_API_KEY === '') {
    throw new Error('REACT_APP_SMARTY_API_KEY is required');
  }
  if (!/^http(s)?:/i.test(configs.EXPRESS_VAS)) {
    throw new Error('Expected EXPRESS_VAS to be valid uri');
  }
  if (!/^http(s)?:/i.test(configs.TCPA_API_BASE_URL)) {
    throw new Error('Expected TCPA_API_BASE_URL to be valid uri');
  }

  initialized = true;
};

/**
 * Get configs.
 *
 * @throws {Error} Will throw error if configs have not been initialized.
 */
export const getConfigs = () => {
  if (!initialized) {
    initConfigs();
  }
  return configs;
};

/**
 * Set allowed config value by key.
 */
export const setConfig = (key, value) => {
  if (!initialized) {
    initConfigs();
  }

  const mutableConfigKeys = {
    'BUYFLOW_ENABLED': ['true', 'false'],
  };

  if (Object.prototype.hasOwnProperty.call(configs, key) && (mutableConfigKeys?.[key]?.includes(value) || mutableConfigKeys?.[key]?.length === 0)) {
    configs[key] = value;
  }
}
